html {
  scroll-behavior: smooth;
}

.bg-hero {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(168, 75, 255, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  );
  background: url(./assets/bg-hero.jpg);
  background-position: center;
}

.bg-hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  filter: brightness(0.8);
  pointer-events: none;
}

.bike-card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
  position: relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 2px;
  background-color: #000;
  transform: rotate(0);
  transition: all 0.3s;
}

#menu {
  transition: all 0.5s;
}

.hamburger-middle {
  transform: translateY(7px);
}

.hamburger-bottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg);
  transform: translateY(0px);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translate(6px);
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translate(-6px);
}
